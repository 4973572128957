import Vue from 'vue'
import capitalize from './capitalize'
import convertThousands from './convert-thousands'
import formatDate from './format-date'
import chunkArray from './chunk-array'

Vue.mixin({
  filters: {
    capitalize,
    convertThousands,
    formatDate,
    chunkArray,
  }
})
