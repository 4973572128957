export default function (str) {
  if (!str || typeof str !== 'string') {
    return str
  }

  return str.trim().split(' ')
    .map(s => {
      return s ? s[0].toUpperCase() + s.substr(1).toLowerCase() : s
    })
    .join(' ')
}
